import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import { useNavigate } from "../../hooks/navigation";
import FirstContactComponent from "../../components/first-contact";
import { sendFirstContactThunk } from "../../redux/actions/first-contact-actions";
import { getSpaceThunk, getSpaceUnavailableDateRangesThunk, resetSpace } from "../../redux/actions/space-actions";
import SigninModal from "../../components/signin-modal";
import SimpleDialog from "../../components/simple-dialog";
import { setCurrentAsset, getSpaceTypesThunk, resetCurrentAsset } from "../../redux/actions/misc-actions";
import { sendBookingRequestThunk } from "../../redux/actions/consultation-actions";
import { CONSULT_STATUS } from "../../redux/reducers";
import { isDateInRangeInclusive, getDays } from "../../utils/dateUtils";
import { getSpaceSlug } from "../../utils";
import api from "../../api";

const FirstContactContainer = () => {
  const { navigate } = useNavigate();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { i18n } = useLingui();

  const bookingId = useSelector((state) => state.firstContact.bookingId);
  const space = useSelector((state) => state.listing.data);
  const assetId = useSelector((state) => state.listing.data.asset_id);
  const status = useSelector((state) => state.firstContact.status);
  const error = useSelector((state) => state.firstContact.error);
  const bookingData = useSelector((state) => state.consultation.data);
  const user = useSelector((state) => state.user.data);
  const userStatus = useSelector((state) => state.user.status);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const unavailableDatesRange = useSelector((state) => state.listing.unavailableDatesRange);

  const [isDateAvailable, setIsDateAvailable] = useState(true);
  const [booking, setBooking] = useState({});
  const [spaceActivities, setSpaceActivities] = useState();
  const [spaceActivitiesCategories, setSpaceActivitiesCategories] = useState();
  const [requestStatus, setRequestStatus] = useState("initial");
  const { start, end, spot } = queryString.parse(location.search);

  const handlePaymentPlan = () => {
    if (spot === "retail" && getDays(start, end) > 59) {
      return "Monthly";
    }
    return "Total";
  };

  const getPrice = async () => {
    if (Number(params.spaceId) > 0) {
      const data = await api.getPriceByDateRange({
        id: params.spaceId,
        start,
        end,
      });
      setBooking({
        startDate: start,
        endDate: end,
        price: data?.data?.payload?.price,
        asset: space?.asset,
      });
    } else {
      setBooking({
        startDate: start,
        endDate: end,
      });
    }
  };

  useEffect(() => {
    dispatch(getSpaceTypesThunk());
  }, []);

  useEffect(() => {
    if (bookingData.state === CONSULT_STATUS.REQUESTED) {
      dispatch(resetCurrentAsset());
      dispatch(resetSpace());
      navigate(`/tenant/inprogress/chat/${bookingData.bookingId}`);
    }
  }, [bookingData]);

  useEffect(() => {
    if (status === "success") {
      setRequestStatus("loading");
      dispatch(sendBookingRequestThunk({ bookingId }));
    }
  }, [status, bookingId, dispatch]);

  useEffect(() => {
    unavailableDatesRange.forEach((d) => {
      if (isDateInRangeInclusive(start, d.start, d.end)) {
        setIsDateAvailable(false);
      }
      if (isDateInRangeInclusive(end, d.start, d.end)) {
        setIsDateAvailable(false);
      }
    });
  }, [unavailableDatesRange]);

  useEffect(() => {
    dispatch(getSpaceThunk(params.spaceId));
    dispatch(getSpaceUnavailableDateRangesThunk(params.spaceId));
  }, [dispatch, params?.spaceId]);

  useEffect(() => {
    if (space) {
      dispatch(setCurrentAsset(space.asset));
      getPrice();
    }
    if (assetId > 0 && isAuthenticated) {
      api.getAssetSpaceActivities(assetId).then((res) => {
        if (res.status === 200) {
          let categories = new Set(res.data?.payload.map((c) => c.category));
          categories = Array.from(categories);
          setSpaceActivitiesCategories(categories);
          setSpaceActivities(res.data?.payload);
        }
      });
    }
  }, [space, assetId, isAuthenticated]);

  const handleSubmit = (message) => {
    dispatch(
      sendFirstContactThunk({
        message,
        space,
        start,
        end,
        paymentPlan: handlePaymentPlan(),
      }),
    );
  };

  return (
    <>
      <FirstContactComponent
        space={space}
        booking={{ bookingId, ...booking }}
        spaceActivities={spaceActivities}
        spaceActivitiesCategories={spaceActivitiesCategories}
        user={user}
        onSubmit={handleSubmit}
        error={error}
        status={status}
        requestStatus={requestStatus}
      />
      {isDateAvailable && <SigninModal isOpen={!isAuthenticated && userStatus !== "loading"} />}

      {!isDateAvailable && (
        <SimpleDialog
          open
          title={i18n._(t`Lo sentimos`)}
          text={i18n._(t`Las fechas elegidas no están disponibles.`)}
          acceptText={i18n._(t`Volver`)}
          onAccept={() => {
            navigate(`/space/${getSpaceSlug(space)}`);
          }}
        />
      )}
    </>
  );
};

export default FirstContactContainer;
