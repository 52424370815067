import { Box, Button, Container, Typography, Hidden, MenuItem, ListSubheader } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AttachFileSharpIcon from "@material-ui/icons/AttachFileSharp";
import React, { useRef, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import SpaceInfoPaper from "../space-info-paper";
import Select from "../select/select-validator";
import TextField from "../text-field";
import NotContact from "./not-contact";
import "../space-features-translations";
import theme from "../../theme";

const useStyles = makeStyles((th) => ({
  container: {
    display: "flex",
    flex: 1,
    padding: 0,
    paddingTop: th.spacing(4),
    paddingLeft: th.spacing(4),
    paddingRight: th.spacing(4),
  },
  root: {
    flexGrow: 1,
  },
  inputLabel: {
    marginTop: th.spacing(2),
  },
  submit: {},
  description: {
    marginTop: theme.spacing(2),
  },
  dossierContainer: {
    display: "none",
    "& > div": {
      width: "100%",
    },
  },
  inputAttachCover: {
    display: "flex",
    position: "absolute",
    top: 40,
    alignItems: "center",
    width: "100%",
    "&> p": {
      opacity: 0.7,
      flex: 5,
      paddingLeft: th.spacing(2),
    },
    "&> svg": {
      flex: 0.5,
      transform: "rotate(-45deg)",
    },
    "&> button": {
      height: 48,
    },
  },
  inputs: {
    marginTop: th.spacing(1),
  },
  selectField: {
    marginTop: th.spacing(1),
    width: "100%",
  },
  selectInput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 0,
    border: th.borders[1],
    width: "100%",
    height: "38.44px",
    padding: th.spacing(0),
  },
  subHeader: {
    marginBottom: th.spacing(1),
  },
  divider: {
    width: "100%",
    border: th.borders[6],
    marginTop: th.spacing(1),
    marginRight: th.spacing(2),
  },
  field: {
    marginBottom: 0,
  },
}));
const INITIAL_VALUES = {
  brandname: "",
  projectname: "",
  dossier: "",
  projectCategory: "",
  description: "",
};

const FirstContactComponent = ({
  user = {},
  space,
  booking,
  spaceActivities = [],
  spaceActivitiesCategories = [],
  onSubmit,
  error,
  status,
  requestStatus,
}) => {
  const classes = useStyles(theme);
  const inputRef = useRef();
  const [values, setValues] = useState(INITIAL_VALUES);
  const { i18n } = useLingui();

  const prospectBookingPrice = space.asset.config?.show_space_price ? booking.price : "";

  const handleErrors = (err) => {
    switch (err) {
      case "space_not_available":
        return i18n._(t`Espacio no disponible.`);
      case "specified_date_range_is_greater_than_max_allowed_stay":
        return i18n._(t`La cantidad de días seleccionados es superior a la cantidad permitida para el espacio`);
      default:
        return `${i18n._(t`Lo sentimos, hubo un error al procesar tu solicitud, vuelve a intentar por favor.`)}`;
    }
  };

  const handleInputChange =
    (field) =>
    ({ target }) => {
      setValues((state) => ({ ...state, [field]: target.value }));
    };

  const handleFileChange = ({ target }) => {
    const file = target.files.length && target.files[0];
    if (file) {
      setValues((state) => ({ ...state, dossier: file }));
    }
  };

  const handleSubmit = () => onSubmit(values);
  const handleClickAttach = () => inputRef.current.click();

  const renderSelectWithGroups = (group) => {
    const selectComponent = [];

    selectComponent.push(
      <ListSubheader>
        <Typography
          variant="subtitle1"
          style={{
            color: theme.palette.black,
          }}
        >
          {i18n._(t`${group}`)}
        </Typography>
        <Box className={classes.divider} />
      </ListSubheader>,
    );

    selectComponent.push(
      spaceActivities
        .filter((sa) => sa.category === group)
        .map((sa) => (
          <MenuItem key={sa.id} value={sa.id}>
            <Box pl={2}>{i18n._(t`${sa.space_activity}`)}</Box>
          </MenuItem>
        )),
    );

    return selectComponent;
  };

  return user.isOwner ? (
    <NotContact />
  ) : (
    <>
      <Container className={classes.container}>
        <Box className={classes.root} display="flex">
          <Box width={["100%", "60%"]}>
            <Box>
              <Typography
                variant="h2"
                style={{
                  color: theme.palette.text.primary,
                  textAlign: "center",
                }}
              >
                <Trans>EL PROPIETARIO QUIERE SABER DE TI</Trans>
              </Typography>
              <Typography variant="subtitle1" className={classes.description}>
                {`${i18n._(t`¡Hola! El propietario necesita saber un poco más sobre tu producto y tu evento.`)}`}
              </Typography>
            </Box>
            <ValidatorForm onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" mt={4}>
                <Typography variant="subtitle1">
                  <Trans>¿Cuál es el nombre de la marca?*</Trans>
                </Typography>
                <TextField
                  inputContainerClassNames={`${classes.field}`}
                  placeholder={`${i18n._(t`Nombre de la marca`)}`}
                  value={values.brandname}
                  onChange={handleInputChange("brandname")}
                  validators={["required", "maxStringLength:30"]}
                  className={classes.inputs}
                  errorMessages={[`${i18n._(t`Campo requerido`)}`, `${i18n._(t`Nombre demasiado extenso`)}`]}
                  fullWidth
                />
                <Box mt={2}>
                  <Typography variant="subtitle1">
                    <Trans>¿Cuál es el nombre de tu producto o proyecto?*</Trans>
                  </Typography>
                </Box>
                <TextField
                  inputContainerClassNames={`${classes.field}`}
                  className={classes.inputs}
                  placeholder={`${i18n._(t`Nombre de tu producto o proyecto`)}`}
                  value={values.projectname}
                  onChange={handleInputChange("projectname")}
                  validators={["required", "maxStringLength:40"]}
                  errorMessages={[`${i18n._(t`Campo requerido`)}`, `${i18n._(t`Nombre demasiado extenso`)}`]}
                  fullWidth
                />

                <Box>
                  <Box mt={2}>
                    <Typography variant="subtitle1">
                      <Trans>¿Cuál es la actividad que vas a realizar?*</Trans>
                    </Typography>
                  </Box>
                  <Select
                    value={values.spaceActivityCoefficient}
                    onChange={handleInputChange("spaceActivityCoefficient")}
                    validators={["required"]}
                    errorMessages={[`${i18n._(t`Elige la actividad a realizar por favor`)}`]}
                    InputProps={{
                      className: classes.selectInput,
                    }}
                    className={classes.selectField}
                  >
                    {spaceActivitiesCategories.map((sac) => renderSelectWithGroups(sac))}
                  </Select>
                </Box>
                <Box
                  display="flex"
                  position="relative"
                  onClick={handleClickAttach}
                  className={classes.dossierContainer}
                >
                  <Box mt={2}>
                    <Typography variant="subtitle1">
                      <Trans>Adjunta un dossier de tu marca en pdf (opcional)</Trans>
                    </Typography>
                  </Box>
                  <TextField
                    inputContainerClassNames={`${classes.field}`}
                    // eslint-disable-next-line no-return-assign
                    inputRef={(el) => (inputRef.current = el)}
                    type="file"
                    className={classes.inputs}
                    placeholder={`${i18n._(t`Dossier de tu marca`)}`}
                    inputProps={{
                      accept: "image/jpg,application/pdf",
                      style: { opacity: 0 },
                    }}
                    onChange={handleFileChange}
                    fullWidth
                  />
                  <Box className={classes.inputAttachCover}>
                    <Typography variant="body1">
                      {values.dossier ? values.dossier.name : `${i18n._(t`Dossier de tu marca`)}`}
                    </Typography>
                    <AttachFileSharpIcon />
                    <Button size="large" variant="contained" color="secondary" className={classes.attach}>
                      <Trans>Adjuntar</Trans>
                    </Button>
                  </Box>
                </Box>
                <Box mt={2}>
                  <Typography variant="subtitle1">
                    <Trans>Cuenta en unas lineas lo que planeas hacer*</Trans>
                  </Typography>
                </Box>
                <TextField
                  multiline
                  rows={4}
                  validators={["minStringLength:1", "maxStringLength:500"]}
                  errorMessages={[
                    `${i18n._(t`Por favor describe tu proyecto`)}`,
                    `${i18n._(t`Máximo de 500 caracteres`)}`,
                  ]}
                  value={values.description}
                  onChange={handleInputChange("description")}
                  className={classes.inputs}
                  fullWidth
                />
              </Box>
              {status === "error" && (
                <Typography variant="body1" color="error">
                  {handleErrors(error)}
                </Typography>
              )}
              <Box display="flex" flexDirection="row" justifyContent="center" mt={3} mb={3}>
                <Button
                  disabled={status === "loading" || requestStatus === "loading"}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  <Trans>GUARDAR INFORMACIÓN</Trans>
                  {(status === "loading" || requestStatus === "loading") && "..."}
                </Button>
              </Box>
            </ValidatorForm>
          </Box>
          <Hidden smDown>
            <Box width="35%" pl={2}>
              <SpaceInfoPaper
                space={space}
                booking={booking}
                fromPrice={prospectBookingPrice}
                hideCancelRequestButton
              />
            </Box>
          </Hidden>
        </Box>
      </Container>
    </>
  );
};

export default FirstContactComponent;
